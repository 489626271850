import React from 'react';

import { FaqPage } from '@kali/theme/lib/pages/misc/faq';
import Layout from '../components/layout';

export default function FaqRoute() {
  const faqs = [
    {
      q:
        'What do you think is the most important factor to consider when hiring a photographer?',
      a: (
        <p>
          Ask yourself these questions. Will your photos still feel authentic in
          20 years? Will a savings of $1,000 or $2,000 really make a difference
          in the long run? When the cake has been eaten, the flowers have dried
          up, and the dress has been stored away, your photos will last a
          lifetime, preserving your story for future generations. There are no
          redos and you absolutely can not put a value on preserving these once
          in a lifetime moments. We believe your photos should be just as
          relevant in 20-30 years as they are today.
        </p>
      ),
    },
    {
      q: 'What sets you apart from the crowd?',
      a: (
        <p>
          We understand that planning a wedding can be stressful and you have a
          lot of things to consider. Worrying about photography shouldn’t be one
          of them. We promise do everything we can to make your experience with
          us an amazing one. 1st. We only use high end, professional equipment
          that produce that most beautiful images. 2. We don’t double book our
          weekends. This allows us to give you the undistracted attention you
          deserve on your wedding day, as well as the week leading up to it. 3.
          We deliver images that will be just as relevant in 20-30 years as they
          are today. 4. We have backup’s for all of our gear as well as our
          external hard drives.
        </p>
      ),
    },
    {
      q: 'How would you describe your particular style?',
      a: (
        <p>
          Our signature fine art style is dreamy and romantic, inspired by
          pastel color palettes and soft textures. Because we don’t over edit
          our images our couples will look back at their photographs in 30 years
          and relive the moments as they were.
        </p>
      ),
    },
    {
      q: 'What are your prices?',
      a: (
        <p>
          We offer prices that accommodate most budgets. Email us today for a
          custom quote!
        </p>
      ),
    },
    {
      q: 'WHAT ABOUT SALES TAX? ',
      a: (
        <p>
          Sales tax of 9.5% is added to all packages for weddings taking place
          in California, as our business is legally based in the California.
        </p>
      ),
    },
    {
      q: 'Where are you based and do you travel?',
      a: (
        <p>
          We are based in Southern California! There are no additional travel
          fees for weddings in Southern California. For other locations, please
          contact us for a customized quote
        </p>
      ),
    },
    {
      q: 'How many images will we receive from our wedding day?',
      a: (
        <p>
          We deliver a minimum of 75 images per hour of shooting time. Most
          wedding collections include at least 8 hours of coverage so we are
          able to tell the entire story! This means you will have roughly 600
          high resolution, professionally edited images in your gallery. For
          engagement sessions, you can expect 50-60 high resolution,
          professionally edited images in your gallery.
        </p>
      ),
    },
    {
      q: 'DO YOU WORK WITH A SECOND SHOOTER?',
      a: (
        <p>
          Yes! We realize the value of having two unique sets of experienced
          eyes covering your day. Every wedding collection includes a second
          photographer. We edit all of the images taken by our second
          photographers to ensure consistency. We work with a variety of
          seasoned professionals, all who have styles similar to our own and who
          own their own photography businesses.
        </p>
      ),
    },
    {
      q: 'DO YOU SHOOT FILM OR DIGITAL?',
      a: (
        <p>
          Both! We feel that both mediums have their own advantages and produce
          beautiful images. All of our digital images are edited to look like
          film and to match our film scans, ensuring that your wedding gallery
          will flow seamlessly.
        </p>
      ),
    },
    {
      q: 'WHAT IS YOUR APPROACH IN CAPTURING THE WEDDING STORY?',
      a: (
        <p>
          We have a very hands-on approach to setting up a wedding day timeline,
          and our goal is to ensure that you have the best natural light for
          each part of your celebration. We also prefer to help build a timeline
          (together with your planner/designer) that gives you plenty of time
          for all photographs throughout the day with buffers to ensure you have
          a smooth and enjoyable experience. We balance art directing, styled
          detail shots and portraits, and capturing the moments that happen
          organically through photojournalistic coverage. The end result yields
          a beautiful balance of people, details and emotion.
        </p>
      ),
    },
    {
      q: 'WHAT EQUIPMENT DO YOU USE?',
      a: (
        <p>
          We use the same equipment as some of the top artists in our industry.
          Canon 5d mark iv for digital. Contax 645 for film. We always bring
          camera backups, and all of our equipment is regularly serviced.
        </p>
      ),
    },
    {
      q: 'What is your backup system like for our wedding images?',
      a: (
        <p>
          Making sure your images are stored safely is something that we take
          extremely seriously. Our digital cameras have two memory card slots
          which means your photos are stored in two places, just in case one
          card fails. After your wedding, your images are backed up to two hard
          drives (again, in case of failure). From there, we keep all of the
          back ups in a fire proof safe.
        </p>
      ),
    },
    {
      q: 'CAN I CUSTOMIZE A COLLECTION?',
      a: (
        <p>
          Absolutely. We are happy to tailor any collection to best suit your
          celebration. Please email us your wish list. We will follow up with a
          custom collection for your review.
        </p>
      ),
    },
    {
      q: 'IS TRAVEL/ACCOMMODATION INCLUDED IN YOUR PRICING?',
      a: (
        <p>
          Travel is not included. As each wedding is unique, we love to learn
          about your destination and celebration before we discuss travel plans.
          We then customize a quote based on the location and number of shooting
          days. The travel quote is added to your selected Wedding Collection
          and we handle all of our own travel arrangements unless otherwise
          arranged and specified.
        </p>
      ),
    },
    {
      q:
        'WHAT HAPPENS IF KALINA GETS SICK OR HAS SOME KIND OF EMERGENCY RIGHT BEFORE THE WEDDING?',
      a: (
        <p>
          Having an emergency come up immediately before a wedding is certainly
          an unpleasant thought and a circumstance we have yet to encounter
          (*knock on wood*). Leading up to a wedding, we plan our lives
          carefully to ensure our health is in top form and that we have ample
          travel time. Should unforeseen circumstances prevent us from attending
          your wedding, we will do our best to arrange a replacement
          photographer of equivalent caliber and style, or simply return all of
          you money and allow you to make your own arrangements if you so
          choose. We have a strong network of associations to aid in such an
          unlikely circumstance
        </p>
      ),
    },
    {
      q: 'HOW DO I CONFIRM MY WEDDING DATE?',
      a: (
        <p>
          Once you have selected a collection, we will prepare and send a
          photography contract for your review. We ask that you return this
          within 7-10 days. Once we receive your signed contract and the 50
          percent photography retainer, your wedding date is officially booked!
          The final balance is due four weeks prior to the wedding date.
        </p>
      ),
    },
    {
      q: 'How far in advance should I book your services and how do we book?',
      a: (
        <p>
          We suggest 6-12 months in advance, or as soon as you know the your
          date and venue. We only accept 15 weddings each year to provide our
          couples with the best care attention.
        </p>
      ),
    },
    {
      q:
        'SHOULD I MAKE A LIST OF MY DESIRED PHOTOS FOR THE WEDDING PHOTOGRAPHER?',
      a: (
        <p>
          While it isn’t necessary to create a shot list for the entire day (we
          are very thorough and detail oriented), we recommend bringing to our
          attention any special people, moments or details you’d like to
          capture. On your wedding day, please place the rings, dress, shoes,
          jewelry and any other heirlooms you’d like in the photos in the bridal
          suite. We will spend the first hour of our coverage shooting your
          details and final hair/makeup.
        </p>
      ),
    },
    {
      q: 'WHO SHOULD BE INCLUDED IN OUR FAMILY PHOTOS?',
      a: (
        <p>
          We will first photograph you with your immediate family members
          (parents and siblings) and grandparents (if applicable) during the
          allotted formal family time. If you have groupings outside of
          immediate family, we suggest you send us a list at least two weeks
          prior to your wedding so we can schedule our time accordingly.
          Additionally, please inform us of any special groupings due to family
          circumstances (i.e divorce, stepparents, etc).
        </p>
      ),
    },
    {
      q: 'WHAT ARE IMAGE TURNAROUND TIMES?',
      a: (
        <p>
          Our work is focused on creating exquisite art using the finest methods
          and quality products. With a passion for the details, we carefully
          curate and present each and every project we photograph. Shooting film
          is not the fastest method, but we see it as the most beautiful.
          Turnaround times for engagement and portrait sessions average 4 to 5
          weeks. Turnaround times for wedding images average 6 to 8 weeks.
        </p>
      ),
    },
    {
      q: 'How do I receive my photographs?',
      a: (
        <p>
          Every collection includes an online gallery with downloading. This
          makes it easy to put your images on your computer, share with friends
          and family, and post to your social media. Also from your gallery,
          you'll be able to order high quality prints directly from our lab.
        </p>
      ),
    },
    {
      q: 'HOW DO YOU GET THOSE PERFECT INVITATION PHOTOS?',
      a: (
        <p>
          First, details are immensely important to us and creating beautiful
          images of your invitation suite helps to tell the story of your
          wedding day. We prefer to shoot these paper suites in advance of the
          wedding day in order to style them elegantly. We kindly request that
          you provide two perfect copies of each piece of your invitation suite.
          Please include calligraphy, vintage stamps or other accessories along
          with any additional paper items for styling, such as a save the date,
          escort card, etc. Feel free to be creative as our goal is to make the
          images look beautiful. After photographing the invitation suite, we
          will bring it on the wedding day to use with the still life shots
          (i.e. rings) before returning it to you. Once you have the invitation
          suite ready to ship to us, please email us and we’ll send our mailing
          address.
        </p>
      ),
    },
    {
      q: 'HOW DO YOU ENSURE THAT YOU STAY ON SCHEDULE DURING THE DAY?',
      a: (
        <p>
          Staying on schedule is one of the most important factors of our work.
          We plan carefully with your wedding planner or person in charge of
          logistics to create a custom minute-by-minute photo timeline for each
          event. Adhering to our photo timeline ensures that we are able to
          capture every incredible moment and detail of your celebration in
          optimal lighting conditions.
        </p>
      ),
    },
    {
      q: 'DO YOU OFFER VIDEOGRAPHY?',
      a: (
        <p>
          We do not offer videography. If you’re interested in hiring a
          videographer we can recommend 618 Studios.
        </p>
      ),
    },
    {
      q: 'WHY SHOULD I CONSIDER WORKING WITH A WEDDING PLANNER/DESIGNER?',
      a: (
        <p>
          Weddings are once-in-a-lifetime events filled with elegant details,
          timelines, loved ones and lots of emotion. A wedding planner can take
          your vision and enhance it beyond what you can imagine, allowing you
          to enjoy a stress-free wedding day. We work closely with each planner
          to ensure we are afforded the opportunity to capture all the beautiful
          elements of your wedding day. Photographing your wedding is much more
          efficient and smooth when we work with a talented planner, as we are
          able to focus solely on creating captivating imagery while the wedding
          logistics are safely in their hands. A few planners we recommend:
          Chloe + Mint, So Happi Together, Details Darling,
        </p>
      ),
    },
    {
      q: 'WHAT Southern California Wedding VENUES DO YOU RECCOMEND?',
      a: (
        <p>
          Our favorite southern California venues are Malibu Rocky Oaks, Kestrel
          Park, Greystone Mansion, Hummingbird Nest Ranch, Brookview Ranch, San
          Ysidro Ranch, Sunstone Winery, Bel-Air Bay Club Events, Saddlerock
          Ranch, Cielo Farms, Rancho Las Lomas, Shutters on the Beach, The
          Adamson House, The Four Seasons Santa Barbara, Belmond El Encanto, The
          Resort at Pelican Hill, The Montage Laguna Beach and Monarch Beach
          Resort.
        </p>
      ),
    },
  ];

  return (
    <Layout title="F.A.Q.">
      <FaqPage faqs={faqs} />
    </Layout>
  );
}
